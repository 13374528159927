












































import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator'
import Dialog from '@/components/Dialog.vue'
import Button from '@/components/Button.vue'

@Component({
  components: {
    Dialog,
    Button
  }
})
export default class ConfirmationDialog extends Vue {
  confirming: boolean = false

  @Prop()
  @Model('change')
  value: boolean

  @Prop({ default: 'danger' })
  variant: 'danger' | 'brand'

  @Prop()
  action: () => Promise<void>

  @Prop()
  buttonText: string

  async del() {
    this.confirming = true
    await this.action()
    this.updateValue(false)
    this.confirming = false
  }

  @Emit('change')
  updateValue(value: boolean) {
    return value
  }
}
