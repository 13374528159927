import { FarmCamera } from '@/models/FarmCamera'

export class FarmCameraConverter
  implements firebase.firestore.FirestoreDataConverter<FarmCamera> {
  toFirestore(item: FarmCamera): firebase.firestore.DocumentData {
    return {
      caption: item.caption,
      active: item.active,
      index: item.index
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const camera = new FarmCamera(snapshot.ref)
    camera.caption = data.caption
    camera.active = data.active
    camera.index = data.index
    return camera
  }
}
