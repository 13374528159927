import { FarmSector } from '@/models/FarmSector'
import { Model } from '@/models/Model'

class FarmPeriodSector extends Model {
  sector: FarmSector
  availableCapacity: number
  totalCapacity: number

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { FarmPeriodSector }
