import { FarmPeriodSector } from '@/models/FarmPeriodSector'
import { FarmSector } from '@/models/FarmSector'

export class FarmPeriodSectorConverter
  implements firebase.firestore.FirestoreDataConverter<FarmPeriodSector> {
  toFirestore(periodSector: FarmPeriodSector): firebase.firestore.DocumentData {
    return {
      sector: periodSector.sector.reference,
      available_capacity: periodSector.availableCapacity,
      total_capacity: periodSector.totalCapacity
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const sector = new FarmPeriodSector(snapshot.ref)
    sector.sector = new FarmSector(data.sector)
    sector.availableCapacity = data.available_capacity ?? 0
    sector.totalCapacity = data.total_capacity ?? 0
    return sector
  }
}
