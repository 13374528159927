

























import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class WeekdayChooser extends Vue {
  @Prop()
  @Model('change')
  value: number[]

  @Prop()
  availableWeekdays: number[]

  days: Array<{ index: number; day: string; selected: boolean }> = [
    { index: 1, day: 'Montag', selected: false },
    { index: 2, day: 'Dienstag', selected: false },
    { index: 3, day: 'Mittwoch', selected: false },
    { index: 4, day: 'Donnerstag', selected: false },
    { index: 5, day: 'Freitag', selected: false },
    { index: 6, day: 'Samstag', selected: false },
    { index: 7, day: 'Sonntag', selected: false }
  ]

  get selectedWeekdays() {
    const mappedDays = this.days.map((d) => ({
      index: d.index,
      day: d.day,
      selected: this.value.includes(d.index)
    }))

    if (this.availableWeekdays && this.availableWeekdays.length > 0) {
      return mappedDays.filter((d) => this.availableWeekdays.includes(d.index))
    }
    return mappedDays
  }

  @Emit('change')
  updateValue(value: number[]) {
    return value
  }
}
