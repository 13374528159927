import { FarmCamera } from '@/models/FarmCamera'
import { Model } from '@/models/Model'

class FarmSector extends Model {
  index: number
  capacity: number
  cameras: FarmCamera[]
  
  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { FarmSector }