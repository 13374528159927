

































































































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import Dropdown from '@/components/Dropdown.vue'
import moment from 'moment'
import { formatDate } from '@/services/DateService'

@Component({
  components: {
    Dropdown
  }
})
export default class DatePicker extends Vue {
  @Prop()
  date: Date

  internalDate: Date = null

  @Prop()
  minDate: Date

  @Prop()
  maxDate: Date

  open: boolean = false

  monthNames: { [id: number]: string } = {
    0: 'Jan',
    1: 'Feb',
    2: 'März',
    3: 'April',
    4: 'Mai',
    5: 'Juni',
    6: 'Juli',
    7: 'Aug',
    8: 'Sep',
    9: 'Okt',
    10: 'Nov',
    11: 'Dez'
  }
  month: number

  dates: Date[] = []

  @Watch('date')
  onDateChanged(date: Date) {
    this.setInternalDate(date)
  }

  setInternalDate(date: Date) {
    this.internalDate = date
    this.calculateDays()
  }

  canGoToPreviousMonth() {
    return this.isDateAfterMin(
      moment(this.internalDate).startOf('month').subtract(1, 'day').toDate()
    )
  }

  previousMonth() {
    this.setInternalDate(
      moment(this.internalDate).subtract(1, 'month').toDate()
    )
  }

  canGoToNextMonth() {
    return this.isDateBeforeMax(
      moment(this.internalDate).endOf('month').add(1, 'day').toDate()
    )
  }

  nextMonth() {
    this.setInternalDate(moment(this.internalDate).add(1, 'month').toDate())
  }

  created() {
    this.setInternalDate(this.date)
  }

  dayClass(date: Date) {
    let baseClasses =
      'flex items-center justify-center w-6 h-6 p-2 text-sm text-center transition duration-100 ease-in-out rounded-full'

    if (this.isToday(date)) {
      return `${baseClasses} line-through cursor-default text-white bg-brand-200 hover:bg-brand-200`
    }

    if (!this.isDateInRange(date)) {
      return `${baseClasses} line-through text-gray-300 cursor-default`
    }

    if (this.isSelected(date)) {
      return `${baseClasses} bg-brand-500 text-white`
    }

    if (date.getMonth() !== this.internalDate.getMonth()) {
      baseClasses = `${baseClasses} text-gray-500`
    } else {
      baseClasses = `${baseClasses} text-gray-700`
    }

    return (baseClasses = `${baseClasses} cursor-pointer hover:bg-brand-200`)
  }

  calculateDays() {
    this.dates = []

    const start = moment(this.internalDate)
      .startOf('month')
      .startOf('isoWeek')
      .startOf('day')
    const end = moment(this.internalDate)
      .endOf('month')
      .startOf('day')
      .add(1, 'day')
      .endOf('isoWeek')
      .startOf('day')

    for (
      const m = moment(start);
      m.isBefore(end) || m.isSame(end);
      m.add(1, 'day')
    ) {
      this.dates.push(m.toDate())
    }
  }

  isDateAfterMin(date: Date) {
    return (
      this.minDate == null ||
      moment(date).isAfter(moment(this.minDate)) ||
      moment(date).isSame(moment(this.minDate))
    )
  }

  isDateBeforeMax(date: Date) {
    return this.maxDate == null || moment(date).isBefore(moment(this.maxDate))
  }

  isDateInRange(date: Date) {
    return this.isDateAfterMin(date) && this.isDateBeforeMax(date)
  }

  isToday(date: Date) {
    return moment(date).isSame(moment().startOf('day'))
  }

  isSelected(date: Date) {
    return moment(date).isSame(moment(this.date))
  }

  @Emit()
  selectDate(date: Date) {
    if (!this.isDateInRange(date)) {
      return
    }

    this.$emit('update:date', date)
    this.open = false
  }

  formatDate(date: Date) {
    return formatDate(date)
  }
}
