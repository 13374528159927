import firebase from 'firebase/app'

const buyItem = async (
  assignmentId: string,
  itemId: string,
  weekdays?: number[],
  expiresOn?: Date
): Promise<{ assignmentItemId: string }> => {
  const buyItemFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('buyItem')
  let params: any = {
    assignment_id: assignmentId,
    item_id: itemId
  }

  if (weekdays && expiresOn) {
    params = {
      ...params,
      weekdays,
      expires_on: firebase.firestore.Timestamp.fromDate(expiresOn)
    }
  }

  const result = await buyItemFunction(params)

  return {
    assignmentItemId: result.data.assignment_item_id
  }
}

const stopItem = async (assignmentId: string, assignmentItemId: string): Promise<void> => {
  const stopItemFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('stopItem')
  await stopItemFunction({
    assignment_id: assignmentId,
    assignment_item_id: assignmentItemId
  })
}

export { buyItem, stopItem }
