import { FarmCamera } from '@/models/FarmCamera'
import { FarmSector } from '@/models/FarmSector'

export class FarmSectorConverter
  implements firebase.firestore.FirestoreDataConverter<FarmSector> {
  toFirestore(sector: FarmSector): firebase.firestore.DocumentData {
    return {
      index: sector.index
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const sector = new FarmSector(snapshot.ref)
    sector.index = data.index
    sector.capacity = data.capacity
    sector.cameras = data.cameras
      ? data.cameras.map((cam: any) => new FarmCamera(cam))
      : []
    return sector
  }
}
