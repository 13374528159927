








































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class SlideOver extends Vue {
  @Prop()
  visible: boolean

  backdropVisible: boolean = false

  @Watch('visible')
  onPropertyChanged(value: string) {
    if (value) {
      this.backdropVisible = true
    }
  }
}
