import { User } from '@/models/User'
import { FarmPeriodAssignment } from '@/models/FarmPeriodAssignment'
import { UserOrderItem } from '@/models/UserOrderItem'
import { FarmPeriodSector } from '@/models/FarmPeriodSector'

export class FarmPeriodAssignmentConverter
  implements firebase.firestore.FirestoreDataConverter<FarmPeriodAssignment> {
  toFirestore(
    assignment: FarmPeriodAssignment
  ): firebase.firestore.DocumentData {
    const ass: any = {
      user: assignment.user.reference,
      cutting: {
        variant: assignment.cutting.variant,
        delivery_method: assignment.cutting.deliveryMethod,
        order_item: assignment.cutting.orderItem,
        comment: assignment.cutting.comment,
        address: assignment.cutting.address
          ? {
              formatted_address: assignment.cutting.address.formattedAddress,
              lat: assignment.cutting.address.lat,
              long: assignment.cutting.address.long
            }
          : null,
        sausages: assignment.cutting.sausages
      }
    }

    if (assignment.sector) {
      ass.sector = assignment.sector.reference
    }

    return ass
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const assignment = new FarmPeriodAssignment(snapshot.ref)
    assignment.orderItem = new UserOrderItem(data.order_item)
    assignment.user = new User(data.user)
    assignment.createdAt = data.created_at.toDate()
    assignment.basicFeeding = data.basic_feeding
    if (data.sector) {
      assignment.sector = new FarmPeriodSector(data.sector)
    }

    if (data.cutting) {
      if (data.cutting.address) {
        assignment.cutting.address = {
          formattedAddress: data.cutting.address.formatted_address,
          lat: data.cutting.address.lat,
          long: data.cutting.address.long
        }
      }
      assignment.cutting.variant = data.cutting.variant
      assignment.cutting.deliveryMethod = data.cutting.delivery_method
      assignment.cutting.comment = data.cutting.comment
      if (data.cutting.sausages) {
        assignment.cutting.sausages = data.cutting.sausages
      }
      if (data.cutting.order_item) {
        assignment.cutting.orderItem =
          data.cutting.order_item === 'cash'
            ? data.cutting.order_item
            : new UserOrderItem(data.cutting.order_item)
      }
    }

    return assignment
  }
}
