import { FarmItem } from '@/models/FarmItem'
import { FarmPeriodAssignmentItem } from '@/models/FarmPeriodAssignmentItem'

export class FarmPeriodAssignmentItemConverter
  implements
    firebase.firestore.FirestoreDataConverter<FarmPeriodAssignmentItem> {
  toFirestore(item: FarmPeriodAssignmentItem): firebase.firestore.DocumentData {
    return {
      item: item.item.reference,
      created_at: item.createdAt
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const item = new FarmPeriodAssignmentItem(snapshot.ref)
    item.item = new FarmItem(data.item)
    item.createdAt = data.created_at.toDate()
    if (data.canceled_at) {
      item.canceledAt = data.canceled_at.toDate()
    }
    if (data.expires_on) {
      item.expiresOn = data.expires_on.toDate()
    }
    if (data.weekdays) {
      item.weekdays = data.weekdays
    }
    return item
  }
}
