import { FarmItem } from '@/models/FarmItem'
import { Model } from '@/models/Model'

class FarmPeriodAssignmentItem extends Model {
  createdAt: Date
  canceledAt: Date
  item: FarmItem
  weekdays: number[] = []
  expiresOn: Date

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { FarmPeriodAssignmentItem }
