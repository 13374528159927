























import { FarmCamera } from '@/models/FarmCamera'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Dialog from '@/components/Dialog.vue'
import { Farm } from '@/models/Farm'

@Component({
  components: {
    Dialog
  }
})
export default class Camera extends Vue {
  @Prop()
  farm: Farm

  @Prop()
  camera: FarmCamera

  @Prop({ default: 1000 })
  refreshInterval: number

  @Prop({ default: false })
  expandable: boolean

  @Prop({ default: 'snapshot' })
  type: 'snapshot' | 'stream'

  @Prop({ default: false })
  autoRefresh: boolean

  url: string = null

  private dialogVisible: boolean = false
  private interval: any = null

  @Watch('dialogVisible')
  dialogVisibleChanged() {
    if (this.type === 'snapshot' && !this.autoRefresh) {
      if (this.dialogVisible) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    }
  }

  created() {
    if (this.type === 'stream') {
      this.url = this.buildBaseUrl()
    } else {
      this.buildSnapshotUrl()
      if (this.autoRefresh) {
        this.startTimer()
      }
    }
  }

  buildBaseUrl() {
    return `https://${this.farm.ip}/cameras/${this.camera.id}/${this.type}?token=ef39e305b2374db7869a2204146becb5`
  }

  buildSnapshotUrl() {
    this.url = `${this.buildBaseUrl()}&date=${new Date().toISOString()}`
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.buildSnapshotUrl()
    }, this.refreshInterval)
  }

  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  beforeDestroy() {
    this.stopTimer()
  }
}
