import { CuttingVariant, DeliveryMethod, Position } from '@/models/Types'
import firebase from 'firebase/app'

const calculateCuttingAmount = async (
  pigs: number,
  variant: CuttingVariant,
  deliveryMethod: DeliveryMethod,
  totalSausageWeight: number,
  from: Position,
  to: Position
): Promise<{
  cuttingAmount: number
  deliveryAmount: number
  sausageAmount: number
  totalAmount: number
}> => {
  const calculateCuttingAmountFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('calculateCuttingAmount')
  const response = await calculateCuttingAmountFunction({
    pigs,
    variant,
    delivery_method: deliveryMethod,
    total_sausage_weight: totalSausageWeight,
    from_position: from,
    to_position: to
  })
  return response.data
}

export { calculateCuttingAmount }
