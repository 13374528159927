






















































































































































































































































































































































































































































































































































































































































































































































































import { Component } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import Button from '@/components/Button.vue'
import Header from '@/components/Header.vue'
import Camera from '@/components/Camera.vue'
import Content from '@/components/Content.vue'
import SlideOver from '@/components/SlideOver.vue'
import DatePicker from '@/components/DatePicker.vue'
import EmptyState from '@/components/EmptyState.vue'
import WeekdayChooser from '@/components/WeekdayChooser.vue'
import { FarmPeriod } from '@/models/FarmPeriod'
import { FarmPeriodConverter } from '@/models/converters/FarmPeriodConverter'
import { Farm } from '@/models/Farm'
import { FarmSector } from '@/models/FarmSector'
import { FarmPeriodAssignmentConverter } from '@/models/converters/FarmPeriodAssignmentConverter'
import { FarmPeriodAssignmentItemConverter } from '@/models/converters/FarmPeriodAssignmentItemConverter'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { FarmItemConverter } from '@/models/converters/FarmItemConverter'
import { FarmSectorConverter } from '@/models/converters/FarmSectorConverter'
import { FarmPeriodAssignmentItem } from '@/models/FarmPeriodAssignmentItem'
import { FarmCamera } from '@/models/FarmCamera'
import { FarmCameraConverter } from '@/models/converters/FarmCameraConverter'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'
import moment from 'moment'
import { FarmPeriodAssignment } from '@/models/FarmPeriodAssignment'
import { UserOrderItemConverter } from '@/models/converters/UserOrderItemConverter'
import { ProductConverter } from '@/models/converters/ProductConverter'
import { Product } from '@/models/Product'
import { FarmItem } from '@/models/FarmItem'
import { buyItem, stopItem } from '@/services/ItemService'
import { showNotification } from '@/services/NotificationService'
import { mapWeekdays } from '@/services/DateService'
import Vue from 'vue'
import { FarmPeriodSectorConverter } from '@/models/converters/FarmPeriodSectorConverter'
import { CuttingVariant, DeliveryMethod } from '@/models/Types'
// @ts-ignore
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { calculateDistance } from '@/services/Position'
import { firestore } from 'firebase/app'
import { UserOrderItem } from '@/models/UserOrderItem'
import { calculateCuttingAmount } from '@/services/AssignmentService'
import { formatNumber } from '@/services/NumberService'

@Component({
  components: {
    Loading,
    Button,
    Header,
    Camera,
    Content,
    SlideOver,
    DatePicker,
    WeekdayChooser,
    EmptyState,
    VueGoogleAutocomplete,
    ConfirmationDialog
  }
})
export default class Home extends mixins(UserMixin) {
  loading: boolean = true
  farm: Farm = null
  period: FarmPeriod = null
  sector: FarmSector = null
  product: Product = null
  cuttingProduct: Product
  cameras: FarmCamera[] = []
  items: FarmItem[] = []
  assignmentItems: FarmPeriodAssignmentItem[] = []
  assignment: FarmPeriodAssignment = null
  hasValidAssignment: boolean = false
  updatingCuttingAmount: boolean = false
  dialogVisible: boolean = false

  selectedItem: FarmItem = null
  endDate: Date = null
  loadingItem: { [id: string]: boolean } = {}
  weekdays: number[] = []
  slideOverVisible: boolean = false

  cuttingVariantAmounts: { [variant in CuttingVariant]: number } = {
    whole: 60 * 100,
    gastronomy: 120 * 100,
    fine: 150 * 100,
    fine_vacuum_packed: 220 * 100
  }

  sausages: Array<{ name: string; selectedWeight: number; weights: number[] }> = []

  cuttingVariantDetails: {
    [variant in CuttingVariant]: Array<{
      name: string
      quantity: number
      amount: number
      unit: 'kg' | 'Stk'
      details: string
    }>
  } = {
      whole: [],
      gastronomy: [
        {
          name: 'Filet',
          quantity: -1,
          amount: 1.6,
          unit: 'kg',
          details: 'im Ganzen'
        },
        {
          name: 'Karree',
          quantity: -1,
          amount: 8,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Schopfbraten',
          quantity: -1,
          amount: 6.4,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Kaiserteil (Schale)',
          quantity: -1,
          amount: 5,
          unit: 'kg',
          details: 'im Ganzen'
        },
        {
          name: 'Nuss',
          quantity: -1,
          amount: 3,
          unit: 'kg',
          details: 'im Ganzen'
        },
        {
          name: 'Fricandeau',
          quantity: -1,
          amount: 1,
          unit: 'kg',
          details: 'im Ganzen'
        },
        {
          name: 'Ripperl gemischt',
          quantity: -1,
          amount: 7,
          unit: 'kg',
          details: 'geschnitten'
        },
        {
          name: 'Schlussbraten',
          quantity: -1,
          amount: 4,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Stelzen',
          quantity: -1,
          amount: 4,
          unit: 'Stk',
          details: '+ Stelzenränder'
        },
        {
          name: 'Schulter',
          quantity: -1,
          amount: 7.2,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Bauch',
          quantity: -1,
          amount: 16,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Faschiertes',
          quantity: -1,
          amount: 10,
          unit: 'kg',
          details: ''
        }
      ],
      fine: [
        {
          name: 'Filet',
          quantity: -1,
          amount: 1.6,
          unit: 'kg',
          details: 'im Ganzen'
        },
        {
          name: 'Karree',
          quantity: 8,
          amount: 1,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Schopfbraten',
          quantity: 8,
          amount: 0.8,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Ripperl gemischt',
          quantity: -1,
          amount: 7,
          unit: 'kg',
          details: 'geschnitten'
        },
        {
          name: 'Schnitzel',
          quantity: -1,
          amount: 9,
          unit: 'kg',
          details: 'geschnitten'
        },
        {
          name: 'Schlussbraten',
          quantity: 4,
          amount: 1,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Stelzen',
          quantity: 4,
          amount: -1,
          unit: 'Stk',
          details: '+ Stelzenräder'
        },
        {
          name: 'Schulter',
          quantity: 6,
          amount: 1.2,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Bauch',
          quantity: 8,
          amount: 2,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Faschiertes',
          quantity: 20,
          amount: 0.5,
          unit: 'kg',
          details: 'vakuumverpackt'
        }
      ],
      fine_vacuum_packed: [
        {
          name: 'Filet',
          quantity: -1,
          amount: 1.6,
          unit: 'kg',
          details: 'im Ganzen'
        },
        {
          name: 'Karree',
          quantity: 8,
          amount: 1,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Schopfbraten',
          quantity: 8,
          amount: 0.8,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Ripperl gemischt',
          quantity: -1,
          amount: 7,
          unit: 'kg',
          details: 'geschnitten'
        },
        {
          name: 'Schnitzel',
          quantity: -1,
          amount: 9,
          unit: 'kg',
          details: 'geschnitten'
        },
        {
          name: 'Schlussbraten',
          quantity: 4,
          amount: 1,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Stelzen',
          quantity: 4,
          amount: -1,
          unit: 'Stk',
          details: '+ Stelzenräder'
        },
        {
          name: 'Schulter',
          quantity: 6,
          amount: 1.2,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Bauch',
          quantity: 8,
          amount: 2,
          unit: 'kg',
          details: 'ausgelöst mit Speck und Schwarte'
        },
        {
          name: 'Faschiertes',
          quantity: 20,
          amount: 0.5,
          unit: 'kg',
          details: 'vakuumverpackt'
        }
      ]
    }

  cuttingVariants: CuttingVariant[] = [
    'whole',
    'gastronomy',
    'fine',
    'fine_vacuum_packed'
  ]
  deliveryMethods: DeliveryMethod[] = ['pickup', 'delivery']
  position: { lat: number; long: number } = null
  distanceToFarm: number = null
  kilometerThreshold = 25

  amounts: {
    cuttingAmount: number
    deliveryAmount: number
    totalAmount: number
  } = {
      cuttingAmount: 0,
      deliveryAmount: 0,
      totalAmount: 0
    }

  get pigs(): number {
    return this.product?.meta?.pigs ?? 0
  }

  get totalSausageOrMincedMeatWeight(): number {
    return 10 * this.pigs
  }

  get totalSausageWeight(): number {
    return this.sausages.reduce((curr, acc) => {
      return curr + acc.selectedWeight
    }, 0)
  }

  get remainingWeight() {
    const weight = this.totalSausageOrMincedMeatWeight - this.totalSausageWeight

    return weight
  }

  get formattedRemainingWeight() {
    return `${formatNumber(this.remainingWeight)} kg`
  }

  isSausageWeightValid(name: string, weight: number) {
    const otherWeight = this.sausages.filter((s) => s.name !== name).reduce((curr, acc) => {
      return curr + acc.selectedWeight
    }, 0)

    return otherWeight + weight <= this.totalSausageOrMincedMeatWeight
  }

  calculateCuttingAmount(variant: CuttingVariant) {
    if (this.pigs === 0.5) {
      switch (variant) {
        case 'whole': {
          return 35 * 100
        }
        case 'gastronomy': {
          return 70 * 100
        }
        case 'fine': {
          return 85 * 100
        }
        case 'fine_vacuum_packed': {
          return 120 * 100
        }
      }
    }
    return this.pigs * this.cuttingVariantAmounts[variant]
  }

  get variant(): CuttingVariant {
    return this.assignment.cutting.variant
  }

  set variant(variant: CuttingVariant) {
    this.assignment.cutting.variant = variant
    this.updateCuttingAmount()
  }

  get deliveryMethod(): DeliveryMethod {
    return this.assignment.cutting.deliveryMethod
  }

  set deliveryMethod(method: DeliveryMethod) {
    this.assignment.cutting.deliveryMethod = method
    if (this.assignment.cutting.deliveryMethod === 'pickup') {
      this.assignment.cutting.address = this.distanceToFarm = null
    }
    this.updateCuttingAmount()
  }

  get feedCount(): number {
    return this.assignmentItems.filter(
      (i: FarmPeriodAssignmentItem) => i.item.type === 'feed'
    ).length
  }

  get extraCount(): number {
    return this.assignmentItems.filter(
      (i: FarmPeriodAssignmentItem) => i.item.type === 'extra'
    ).length
  }

  get totalAmount(): number {
    let totalAmount = 0

    if (this.selectedItem) {
      totalAmount = this.totalDays * this.selectedItem.amount
    }
    return totalAmount
  }

  get totalDays(): number {
    let start = moment().startOf('day').add(1, 'day')
    if (start.isBefore(moment(this.period.settingDay))) {
      start = moment(this.period.settingDay)
    }

    const end = moment(this.endDate)

    let days = 0
    for (
      const m = moment(start);
      m.isBefore(end) || m.isSame(end);
      m.add(1, 'day')
    ) {
      const day = this.weekdays.find((d) => d === m.isoWeekday())
      if (day) {
        days++
      }
    }

    return days
  }

  get insufficientFunds() {
    return this.user.credits < this.totalAmount
  }

  async created() {
    if (this.user.assignments.length > 0) {
      for (const assignment of this.user.assignments) {
        const period = (
          await assignment.reference.parent.parent
            .withConverter(new FarmPeriodConverter())
            .get()
        ).data()

        if (moment(period.slaughterDay).isBefore(moment())) {
          continue
        }

        this.hasValidAssignment = true
        await this.loadNextAssignment(assignment)
        break
      }

      this.cuttingProduct = (
        await firestore()
          .collection('products')
          .doc('uunLrRvE6ZOl01xvPslB')
          .withConverter(new ProductConverter())
          .get()
      ).data()

      await this.updateCuttingAmount()
    }

    this.loading = false
  }

  async loadNextAssignment(assignment: FarmPeriodAssignment) {
    this.assignment = (
      await assignment.reference
        .withConverter(new FarmPeriodAssignmentConverter())
        .get()
    ).data()

    const orderItem = (
      await this.assignment.orderItem.reference
        .withConverter(new UserOrderItemConverter())
        .get()
    ).data()

    const [productRef, periodRef, farmRef] = await Promise.all([
      orderItem.product.reference.withConverter(new ProductConverter()).get(),
      this.assignment.reference.parent.parent
        .withConverter(new FarmPeriodConverter())
        .get(),
      this.assignment.reference.parent.parent.parent.parent
        .withConverter(new FarmConverter())
        .get()
    ])
    this.product = productRef.data()
    this.period = periodRef.data()
    this.farm = farmRef.data()

    if (this.assignment.sector) {
      const periodSector = (
        await this.assignment.sector.reference
          .withConverter(new FarmPeriodSectorConverter())
          .get()
      ).data()

      this.sector = (
        await periodSector.sector.reference
          .withConverter(new FarmSectorConverter())
          .get()
      ).data()

      this.cameras = (
        await Promise.all(
          this.sector.cameras.map((cam) =>
            cam.reference.withConverter(new FarmCameraConverter()).get()
          )
        )
      ).map((doc) => doc.data())
    } else {
      const globalCameras = (
        await this.farm.reference
          .collection('cameras')
          .withConverter(new FarmCameraConverter())
          .limit(3)
          .get()
      ).docs.map((doc) => doc.data())

      this.cameras = [...globalCameras]
    }

    this.items = (
      await this.farm.reference
        .collection('items')
        .withConverter(new FarmItemConverter())
        .where('active', '==', true)
        .orderBy('name', 'asc')
        .get()
    ).docs.map((doc) => doc.data())

    this.assignmentItems = (
      await this.assignment.reference
        .collection('items')
        .withConverter(new FarmPeriodAssignmentItemConverter())
        .get()
    ).docs.map((doc) => doc.data()).filter(ai => !ai.canceledAt)

    for (const assignmentItem of this.assignmentItems) {
      assignmentItem.item = (
        await assignmentItem.item.reference
          .withConverter(new FarmItemConverter())
          .get()
      ).data()
    }

    const defaultEndDate = moment(new Date()).add(1, 'month').startOf('day')
    this.endDate = defaultEndDate.toDate()

    if (defaultEndDate.isBefore(moment(this.period.settingDay))) {
      // Next day is before setting day => set default date to setting day (feed cannot be consumed before pig is stalled)
      this.endDate = this.period.settingDay
    }

    this.sausages = [
      {
        name: 'Pusterwurst',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 1) + 1)
          .fill(1)
          .map((value, index) => value * index)
      },
      {
        name: 'Käsekrainer',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 1) + 1)
          .fill(1)
          .map((value, index) => value * index)
      },
      {
        name: 'Knacker',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 1) + 1)
          .fill(1)
          .map((value, index) => value * index)
      },
      {
        name: 'Frankfurter',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 1) + 1)
          .fill(1)
          .map((value, index) => value * index)
      },
      {
        name: 'Debreziner',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 1) + 1)
          .fill(1)
          .map((value, index) => value * index)
      },
      {
        name: 'Käsewurst',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 0.6))
          .fill(0.6)
          .map((value, index) => value * index)
      },
      {
        name: 'Wiener',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 0.6))
          .fill(0.6)
          .map((value, index) => value * index)
      },
      {
        name: 'Polnische',
        selectedWeight: 0,
        weights: Array(Math.ceil(this.totalSausageOrMincedMeatWeight / 0.6))
          .fill(0.6)
          .map((value, index) => value * index)
      }
    ]
  }

  async buyItem(itemId: string, weekdays?: number[], expiresOn?: Date) {
    Vue.set(this.loadingItem, itemId, true)
    try {
      const result = await buyItem(
        this.assignment.id,
        itemId,
        weekdays,
        expiresOn
      )

      const item = (
        await this.assignment.reference
          .collection('items')
          .withConverter(new FarmPeriodAssignmentItemConverter())
          .doc(result.assignmentItemId)
          .get()
      ).data()

      this.assignmentItems.push(item)

      showNotification({
        title: 'Erfolg',
        text: `Das Produkt "${this.selectedItem.name}" wurde gekauft.`,
        type: 'success',
        timeout: 10
      })
    } catch (ex) {
      showNotification({
        title: 'Fehler',
        text: ex.message,
        type: 'error'
      })
    }
    this.slideOverVisible = false
    Vue.set(this.loadingItem, itemId, false)
  }

  async stop(item: FarmPeriodAssignmentItem) {
    Vue.set(this.loadingItem, item.item.id, true)
    await stopItem(this.assignment.id, item.id)

    this.assignmentItems.splice(this.assignmentItems.indexOf(item), 1)

    this.assignmentItems = this.assignmentItems.filter(
      (i) => i.item.id !== item.item.id
    )
    Vue.set(this.loadingItem, item.item.id, false)
  }

  selectItem(item: FarmItem) {
    this.selectedItem = item
    this.slideOverVisible = true
  }

  mapWeekdays(weekdays: number[]) {
    return mapWeekdays(weekdays).join(', ')
  }

  getWeekdayName(day: number): string {
    return moment().day(day).format('dddd')
  }

  async getAddressData(addressData: any, placeResultData: any, id: any) {
    this.position = {
      lat: addressData.latitude,
      long: addressData.longitude
    }

    this.assignment.cutting.address = {
      formattedAddress: placeResultData.formatted_address,
      lat: this.position.lat,
      long: this.position.long
    }

    this.distanceToFarm = Math.ceil(
      Math.ceil(
        calculateDistance(
          this.farm.lat,
          this.farm.long,
          this.assignment.cutting.address.lat,
          this.assignment.cutting.address.long
        )
      ) / 1000
    )

    await this.updateCuttingAmount()
  }

  addCuttingToCart() {
    const item = new UserOrderItem()
    item.product = this.cuttingProduct
    item.params = {
      cutting: {
        address: this.assignment.cutting.address
          ? {
            formatted_address:
              this.assignment.cutting.address.formattedAddress,
            lat: this.assignment.cutting.address.lat,
            long: this.assignment.cutting.address.long
          }
          : null,
        variant: this.assignment.cutting.variant,
        comment: this.assignment.cutting.comment,
        delivery_method: this.assignment.cutting.deliveryMethod,
        sausages: this.getSelectedSausages()
      },
      assignment_path: this.assignment.reference.path,
      amount: this.amounts.totalAmount
    }

    item.amount = this.amounts.totalAmount
    this.mainStore.clearCart()
    this.mainStore.addToCart(item)
    this.$router.push({ name: 'cart' })
  }

  async updateCuttingAmount() {
    this.updatingCuttingAmount = true
    if (this.assignment) {
      const deliveryMethod = this.assignment.cutting.deliveryMethod
      this.amounts = await calculateCuttingAmount(
        this.pigs,
        this.assignment.cutting.variant,
        deliveryMethod,
        this.assignment.cutting.variant === 'whole' ? 0 : this.totalSausageWeight,
        deliveryMethod === 'delivery' && this.assignment.cutting.address
          ? {
            lat: this.assignment.cutting.address.lat,
            long: this.assignment.cutting.address.long
          }
          : null,
        deliveryMethod === 'delivery' && this.assignment.cutting.address
          ? {
            lat: this.farm.lat,
            long: this.farm.long
          }
          : null
      )
    }
    this.updatingCuttingAmount = false
  }

  getSelectedSausages() {
    const selectedSausages = this.sausages.filter((s) => s.selectedWeight > 0)
    if (this.assignment.cutting.variant !== 'whole' && selectedSausages.length > 0) {
      const sausages = selectedSausages.map((s) => ({ name: s.name, weight: +s.selectedWeight.toFixed(2) }))
      return sausages
    }
    return []
  }

  async completeCutting() {
    this.assignment.cutting.orderItem = 'cash'
    this.assignment.cutting.sausages = this.getSelectedSausages()

    await this.assignment.reference
      .withConverter(new FarmPeriodAssignmentConverter())
      .set(this.assignment, {
        merge: true
      })
  }
}


